<template>
  <div class="aws-deloitte-landing-page" id="mainframe-modernization-playbook">
    <section role="main">
      <div class="lp-content">
        <img
          src="/assets/images/logo-footer.svg"
          alt="Logos for AWS and Deloitte"
          class="logo"
        />
        <h1 class="title">5 Best Practices in Mainframe Modernization</h1>
        <p class="subtitle">The quick start guide to mainframe modernization</p>
        <p class="intro">
          Upgrade your enterprise workloads and unlock new value with cloud.
        </p>
        <img
          src="/assets/images/mainframe-ebook.png"
          alt="Picture of the cover for Mainframe Modernization Playbook"
          id="ebook-cover"
        />
        <p class="main-details">
          Fill out the form and download the guide today. Inside this guide, you
          will uncover the best practices to renew your enterprise IT, including
          how to:
        </p>
        <ul class="featured-content">
          <li>
            <div>
              <img
                src="/assets/images/icons/icon-optimize.svg"
                class="featured-content-icon"
                alt=""
              />
              <p>Turn data management into data insights</p>
            </div>
          </li>
          <li>
            <div>
              <img
                src="/assets/images/icons/icon-agility.svg"
                class="featured-content-icon"
                alt=""
              />
              <p>Update non-functional requirements</p>
            </div>
          </li>
          <li>
            <div>
              <img
                src="/assets/images/icons/icon-complexity.svg"
                class="featured-content-icon"
                alt=""
              />
              <p>Go cloud first while leveraging cloud platform services</p>
            </div>
          </li>
          <li>
            <div>
              <img
                src="/assets/images/icons/icon-growth.svg"
                class="featured-content-icon"
                alt=""
              />
              <p>Think cloud-native</p>
            </div>
          </li>
        </ul>
        <p class="callout">
          <strong>Complete the form</strong> to access your complimentary guide
          now.
        </p>
      </div>
      <div class="lp-form">
        <div class="lp-form-content">
          <img src="/assets/images/start-here.svg" alt="Start Here" />
          <p>
            Please complete all fields. Download the complimentary guide today.
          </p>
          <form
            name="mainframe-modernization-best-practices-form"
            id="mainframe-modernization-best-practices-form"
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <input type="hidden" name="oid" value="00D15000000EfGF" />
            <input
              type="hidden"
              name="retURL"
              :value="`${url}/mainframe-modernization-best-practices?download=true`"
            />

            <input
              type="hidden"
              name="Campaign_ID"
              value="7016R000000wVPBQA2"
            />

            <!-- UTM_Source -->
            <input
              type="hidden"
              name="00N1C00000IoupT"
              id="00N1C00000IoupT"
              :value="utmParams.source"
            />
            <!-- UTM Medium -->
            <input
              type="hidden"
              name="00N1C00000IoupY"
              id="00N1C00000IoupY"
              :value="utmParams.medium"
            />
            <!-- UTM Campaign -->
            <input
              type="hidden"
              name="00N1C00000Ioupi"
              id="00N1C00000Ioupi"
              :value="utmParams.campaign"
            />

            <div class="form-group">
              <label for="first_name">First Name</label>
              <input
                type="text"
                class="form-control"
                id="first_name"
                name="first_name"
                size="20"
                required
              />
            </div>
            <div class="form-group">
              <label for="last_name">Last Name</label>
              <input
                type="text"
                class="form-control"
                id="last_name"
                name="last_name"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email Address</label>
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                required
              />
            </div>
            <div class="form-group">
              <label for="phone">Phone Number</label>
              <input
                type="tel"
                pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                class="form-control"
                id="phone"
                name="phone"
                maxlength="40"
                required
              />
            </div>

            <div class="form-group">
              <label for="company">Company</label>
              <input
                type="text"
                class="form-control"
                id="company"
                name="company"
                required
              />
            </div>

            <div class="form-group">
              <label for="title">Job Title</label>
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                required
              />
            </div>
            <div class="form-group">
              <label for="country">Country</label>
              <div class="select">
                <select
                  name="country"
                  id="country"
                  class="form-control"
                  required
                  :class="{ 'country-selected': country }"
                  v-model="country"
                >
                  <option value="" selected="" disabled="" hidden="">
                    Select Your Country
                  </option>
                  <option
                    v-for="country in countriesList"
                    :key="`${country.name} option`"
                    :value="country.name"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <span class="focus"></span>
              </div>
            </div>
            <div class="form-group checkbox-group">
              <input
                type="checkbox"
                required
                id="00N1C00000IkcVh"
                name="00N1C00000IkcVh"
                class=""
              />
              <div>
                <label for="00N1C00000IkcVh">
                  <div class="small">
                    I agree to receive emailed reports, articles, and event
                    invitations from Deloitte. I understand I may unsubscribe at
                    any time by selecting the link included in e-mails.
                  </div>
                </label>
                <div class="small">
                  See Deloitte
                  <a
                    href="https://www2.deloitte.com/us/en/footerlinks1/privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Privacy Terms</a
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                type="submit"
                name="submit"
                id="submit"
                class="btn btn-lg download green-btn mt-5"
              >
                Download Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <the-footer />
    <the-ebook-modal
      v-if="showModal"
      :status="showModal"
      title="5 Best Practices in Mainframe Modernization"
      subtitle="The quick start guide to mainframe modernization"
      type="guide"
      file="/assets/ebooks/AWS-Deloitte-5-Best-Practices-Mainframe-Modernization-ebook.pdf"
      @close="closeModal"
    />
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";
import countriesData from "../data/countries.json";
import { mapState } from "vuex";
import TheEbookModal from "../components/TheEbookModal.vue";

const adrollAdvID = "F4RS5J4SLZDIHN77O7VJQD";
const adrollPixId = "3IE34JSMEJGDBGYAGRPUCU";
const adrollVersion = "2.0";

export default {
  name: "ModernizationPlaybook",
  metaInfo: {
    title: "AWS | Deloitte: 5 Best Practices in Mainframe Modernization",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=UTF-8" },
    ],
    script: [
      {
        type: "text/javascript",
        vmid: "adroll-pixel-setup",
        id: "adroll-pixel-setup",
        innerHTML: `
          adroll_adv_id="${adrollAdvID}";
          adroll_pix_id="${adrollPixId}";
          adroll_version="${adrollVersion}";

          (function(w, d, e, o, a) {
        w.__adroll_loaded = true;
        w.adroll = w.adroll || [];
        w.adroll.f = [ 'setProperties', 'identify', 'track' ];
        for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                return function() {
                    w.adroll.push([ n, arguments ])
                }
            })(w.adroll.f[a])
        }
    })(window, document);
    adroll.track("pageView");`,
      },
      {
        type: "text/javascript",
        vmid: "adroll-pixel",
        id: "adroll-pixel",
        async: true,
        src: `https://s.adroll.com/j/${adrollAdvID}/roundtrip.js`,
      },
    ],
    __dangerouslyDisableSanitizersByTagID: {
      "adroll-pixel": ["innerHTML"],
    },
  },
  components: {
    TheFooter,
    TheEbookModal,
  },
  data: function () {
    return {
      url: process.env.VUE_APP_URL,
      showModal: false,
      country: "",
      countriesList: countriesData,
    };
  },
  computed: mapState({
    utmParams: (state) => state.sessionData.utmParams,
  }),
  mounted() {
    this.$store.dispatch("sessionData/getUtmParams", this.$router.currentRoute);
    if (this.$router.currentRoute.query["download"]) {
      this.showModal = true;
    }
  },
  methods: {
    closeModal: function () {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.aws-deloitte-landing-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
  .lp-content {
    flex-grow: 0;
    width: 90%;

    @media screen and (max-width: 767px) {
      margin: 0 auto;
      padding-left: 5%;
      padding-right: 5%;
    }
    @media screen and (min-width: 768px) {
      width: ((682/1200) * 100%);
      max-width: 682px;
      padding-left: ((37/1200) * 100%);
      padding-bottom: 78.5px;
    }
    .logo {
      display: block;
      max-width: 239.79px;
      margin: 59.3px auto 49px;
    }
    h1 {
      font-weight: normal;
      font-style: italic;
      font-size: ((40/18) * 1rem);
      line-height: (56/40);
      text-align: center;
      color: #01aeeb;
      margin-bottom: 17.4px;
    }
    .subtitle {
      margin: 0;
      font-weight: 600;
      font-size: ((24/18) * 1rem);
      line-height: (26/24);
      text-align: center;
      color: #585858;
      margin-bottom: 17.4px;
      opacity: 1;
    }
    .intro {
      font-weight: normal;
      font-size: ((20/18) * 1rem);
      line-height: (26/20);
      text-align: center;
      color: #000;
      opacity: 0.8;
      margin: 17.4px 0;
    }
    #ebook-cover {
      display: block;
      width: ((584/682) * 100%);
      max-width: 584px;
      margin: 0 auto;
    }
    .main-details {
      font-family: "Open Sans";
      font-weight: normal;
      font-size: 1rem;
      line-height: (22/18);
      text-align: center;
      color: #000;
      margin: 14.7px 0;
    }
    .featured-content {
      margin: 72.8px 0 33.3px;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      li {
        width: 50%;
        margin-bottom: 38px;
        div {
          max-width: 252px;
          margin: 0 auto;
          text-align: center;
          img {
            display: inline-block;
            margin: 0 auto;
          }
          p {
            margin: 10.7px 0 0;
            font-weight: normal;
            font-size: 1rem;
            line-height: (22/18);
            text-align: center;
            color: #000;
          }
        }
      }
    }
    .callout {
      font-size: 1rem;
      line-height: (21/18);
      text-align: center;
      color: #020202;
    }
  }
  .lp-form {
    flex-grow: 0;
    width: 100%;
    background-color: #f3f3f3;
    margin-top: 45px;
    @media screen and (min-width: 768px) {
      background-color: transparent;
      width: ((518/1200) * 100%);
      max-width: 518px;
      position: relative;
      display: flex;
      margin-top: 0;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -36.16px;
        top: 25%;
        margin-top: -10px;
        width: 0;
        height: 0;
        border-top: 36.16px solid transparent;
        border-right: 36.16px solid #f3f3f3;
        border-bottom: 36.16px solid transparent;
        border-left: 36.16px solid transparent;
      }
    }
    .lp-form-content {
      width: 90%;
      padding-bottom: 100px;
      padding-left: 5%;
      padding-right: 5%;
      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
      @media screen and (min-width: 768px) {
        background-color: #f3f3f3;
        padding-top: 52px;
        margin-left: 29.7px;
        width: calc(100% - 29.7px);
        padding-left: ((69/518) * 100%);
        padding-right: 0;
      }
      img {
        margin-left: -30px;
        margin-bottom: -40px;
        padding-top: 80px;
        max-width: 80%;
      }
      p {
        max-width: 320px;
        font-weight: bold;
        font-size: ((16/18) * 1rem);
        line-height: (21/18);
        text-align: center;
        color: #000;
      }
      form {
        width: 100%;
        @media screen and (min-width: 768px) {
          max-width: 322px;
        }
        .form-group {
          margin-bottom: 19.7px;
          .small {
            font-weight: normal;
            font-size: ((13/18) * 1rem);
            line-height: (16/13);
            text-align: left;
            color: #696969;
            a {
              color: #01aeeb;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          &.checkbox-group {
            margin-top: 34px;
            display: flex;
            flex-direction: row;
            label {
              margin-bottom: 1rem;
            }
          }
          label {
            display: block;
            font-weight: 300;
            font-size: ((19/18) * 1rem);
            line-height: (26/19);
            text-align: left;
            color: #000;
            margin-bottom: 2px;
          }
          input {
            font-weight: 300;
            font-size: ((19/18) * 1rem);
            line-height: (24/19);
            text-align: left;
            color: #000;
            display: block;
            width: 100%;
            height: 47.53px;
            line-height: 47.53px;
            border-radius: 3px;
            background: #fff;
            border: 1px solid #4e4e4e;
            padding-left: ((17.1/322.31) * 100%);
            padding-right: ((17.1/322.31) * 100%);
            outline: none;
            &[type="checkbox"] {
              margin-top: 5px;
              margin-right: 15px;
              flex-shrink: 0;
              flex-grow: 0;
              width: 17px;
              height: 17px;
              background: #fff;
              border: 1px solid #707070;
            }
            &:focus {
              border: 2px solid #000;
            }
          }
          .select {
            border-radius: 3px;
            background: #fff;
            border: 1px solid #4e4e4e;
            position: relative;
            display: grid;
            grid-template-areas: "select";
            align-items: center;

            select,
            &:after {
              grid-area: select;
            }
            select {
              border-radius: 3px;
              // A reset of styles, including removing the default dropdown arrow
              appearance: none;
              // Additional resets for further consistency
              border: none;
              width: 100%;
              font-weight: 300;
              font-size: ((19/18) * 1rem);
              line-height: (24/19);
              text-align: left;
              color: #969696;
              display: block;
              width: 100%;
              height: 47.53px;
              padding-left: ((17.1/322.31) * 100%);
              padding-right: 35.61px;
              outline: none;
              &.country-selected,
              option {
                color: #000;
              }

              &:focus + .focus {
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                border: 2px solid #000;
                border-radius: inherit;
              }
            }
            &:after {
              content: "";
              background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='11.509' height='6.461' viewBox='0 0 11.509 6.461'><path id='Path_764' data-name='Path 764' d='M1146.871,4279.978l5.4,5.4,5.4-5.4' transform='translate(-1146.517 -4279.625)' fill='none' stroke='%23707070' stroke-width='1'/></svg>");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: left center;
              width: 35.61px;
              height: 6.461px;
              justify-self: end;
            }
          }

          #submit {
            height: auto;
            display: block;
            border: none;
            width: 100%;
            padding: 18px ((49.7/322) * 100%) 18px;
            margin: 44px 0 33px;
            background: linear-gradient(#85bc22 0%, #01aeeb 100%);
            font-weight: bold;
            font-size: 1rem;
            line-height: (24/18);
            text-align: left;
            text-transform: uppercase;
            color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            transition: 300ms ease;

            @media screen and (min-width: 768px) {
              justify-content: space-between;
              max-width: 322px;
              padding: 18px ((49.7/322) * 100%) 18px ((82/322) * 100%);
            }
            &:after {
              content: "";
              display: block;
              background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8.166' height='14.333' viewBox='0 0 8.166 14.333'><path id='Path_94' data-name='Path 94' d='M1152.929,4270.9l5.752,5.752-5.752,5.753' transform='translate(-1151.515 -4269.483)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/></svg>");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 8.166px;
              height: 14.333px;
              margin-left: 12px;
              @media screen and (min-width: 768px) {
                margin-left: 0;
              }
            }
            &:hover {
              background: linear-gradient(#85bc22 0%, #01aeeb 0%);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.aws-deloitte-landing-page {
  #footer {
    #aws-deloitte-footer {
      background: #fff;
    }
  }
}
</style>
